/*eslint-disable*/
import M$plugin$ZoomPanel from '/home/alvaroramirez/Escritorio/API-CNIG_/API-CNIG/api-ign-js/src/plugins/zoompanel/src/facade/js/zoompanel';
import M$control$ZoomPanelControl from '/home/alvaroramirez/Escritorio/API-CNIG_/API-CNIG/api-ign-js/src/plugins/zoompanel/src/facade/js/zoompanelcontrol';
import M$impl$control$ZoomPanelControl from '/home/alvaroramirez/Escritorio/API-CNIG_/API-CNIG/api-ign-js/src/plugins/zoompanel/src/impl/ol/js/zoompanelcontrol';

if (!window.M.plugin) window.M.plugin = {};
if (!window.M.control) window.M.control = {};
if (!window.M.impl) window.M.impl = {};
if (!window.M.impl.control) window.M.impl.control = {};
window.M.plugin.ZoomPanel = M$plugin$ZoomPanel;
window.M.control.ZoomPanelControl = M$control$ZoomPanelControl;
window.M.impl.control.ZoomPanelControl = M$impl$control$ZoomPanelControl;
